import React, { useEffect, useState } from 'react';
import classes from './MultiConverter.module.scss';
import { useRouter } from 'next/router';
import useCustomMediaQuery from '../../../../../remitbee/customHook';
import theme from '../../../../../remitbee/theme/Theme';
import { calculateCurrencyExchange } from '../../../../../redux/exchange/actions';
import { snackbar } from '../../../../../remitbee/components/snackbar/SnackBar';
import Box from '../../../../../remitbee/components/box/Box';
import CurrencyExchangeBox from '../../../../../remitbee/components/currencyExchangeBox/CurrencyConverterBox';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import Button from '../../../../../remitbee/components/button/Button';
import RequestDialog from '../../../../sendMoneyV2/RecipientDetails/RecipientForm/FormFields/RequestDialog';
import { getCurrencyExchangeRates } from '../../../../../shared/exchange-rates';
import Typography from '../../../../../remitbee/components/typography/Typography';
import { useTranslation } from 'next-i18next';
import HorizontalExchangeConverter from '../../../reusableSections/new/HorizontalExchangeConverter/HorizontalExchangeConverter';

interface Props {
  storybookMock?: any
  type?: string
}

const CurrencyExchangeRateDetails:React.FC<Props> = ({
  type
}) => {
    const router = useRouter();
    const { t } = useTranslation('landing');
    const [sendingCurrency, setSendingCurrency] = useState({
        id: 1,
        country_name: "Canada",
        country_id: 236,
        currency_code: "CAD",
        currency_name: "Canadian Dollar",
        iso2: "CA",
        iso3: "CAN",
    });
    const [receivingCurrency, setReceivingCurrency] = useState({
        id: 1,
        country_name: "United States",
        country_id: 40,
        currency_code: "USD",
        currency_name: "United States Dollar",
        iso2: "US",
        iso3: "USA",
    });
    const [sendingRate, setSendingRate] = useState(null);
    const [receivingRate, setReceivingRate] = useState(null);
    const [receivingAmount, setReceivingAmount] = useState(null);
    const [sendingAmount, setSendingAmount] = useState('1000');
    const [openRequestDialog, setOpenRequestDialog] = useState(false);
    const [sendAmountLoading, setSendAmountLoading] = useState(false);
    const [receiveAmountLoading, setAmountReceivingLoading] = useState(false);
    const [isSwapped, setIsSwapped] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currencyRateLoading, setCurrencyRateLoading] = useState(false);
    
    const isMobile = useCustomMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

    useEffect(() => {
        const initialLoadInfo = async() => {
            setLoading(true);
            await loadTransferRatesRest();
            calculateTransferAmount();
        }
        initialLoadInfo();
        setLoading(false);
    },[]);

    useEffect(()=>{
      calculateTransferAmount(sendingAmount)
    },[sendingCurrency, receivingCurrency])

    const loadTransferRatesRest = async () => {
        try {
            if(!loading) setLoading(true);
            const data = await getCurrencyExchangeRates();
            if(data && data.length > 0){
              const exchangeData = data[0];
              if(!exchangeData) return;

              const unitedStates = {
                id: exchangeData?.id,
                country_name: exchangeData?.country_from,
                country_id: exchangeData?.country_from_id,
                currency_code: exchangeData?.currency_code,
                currency_name: exchangeData?.currency_name,
                iso2: exchangeData?.iso2,
                iso3: exchangeData?.iso3,
              }
              const canada = {
                id: exchangeData?.id,
                country_name: exchangeData?.country_to,
                country_id: exchangeData?.country_to_id,
                currency_code: 'CAD',
                currency_name: "Canadian Dollar",
                iso2: "CA",
                iso3: "CAN",
              }
              setSendingCurrency(canada)
              setReceivingCurrency(unitedStates)
            }
        }
        catch (error) {
            snackbar.error(error.message, 3000)
        }
        finally {
          setLoading(false);
        }
    }
    const calculateTransferAmount = async (transferValue?: string, receivingValue?: string) => {
        try {
          setCurrencyRateLoading(true);
            if(!sendingCurrency?.country_id && !receivingCurrency?.country_id) return;

            const _args: any = {};
            _args.country_from_id = sendingCurrency.country_id;
            _args.country_to_id = receivingCurrency.country_id;
            _args.is_promo_rate = false;
            _args.is_special_rate = true;
            _args.receiving_amount = receivingValue || null;
            _args.transfer_amount = transferValue || null;

            const { success, data, errorMessage } = await calculateCurrencyExchange(_args);
            if (success && data) {
                const exchangeRates = data.CalculateCurrencyExchangeAmount
                setReceivingRate(exchangeRates.rate)
                setSendingRate((1/exchangeRates.rate).toFixed(4))

                if (transferValue) setReceivingAmount(exchangeRates.receiving_amount);
                if (receivingValue) setSendingAmount(exchangeRates.transfer_amount);         
            } else if(errorMessage) throw new Error(errorMessage);
            setCurrencyRateLoading(false);
        }
        catch (error) {
            snackbar.error(error.message, 3000)
        }
    }

    const handleSendAmountChange = async (value: string) => {
      setAmountReceivingLoading(true)
      await calculateTransferAmount(value, null)
      setAmountReceivingLoading(false)
    }

    const handleReceiveAmountChange = async (value: string) => {
      setSendAmountLoading(true)
      await calculateTransferAmount(null, value)
      setSendAmountLoading(false)
    }

    const onSwapHandler = () => {
      setIsSwapped(!isSwapped)
      setSendingCurrency(receivingCurrency)
      setReceivingCurrency(sendingCurrency)

      setSendingRate(receivingRate)
      setReceivingRate(sendingRate)
      setSendingAmount('1000')
    }

  return (
    <>
    {type === 'horizontal' && !isMobile ? 
     <HorizontalExchangeConverter
      id='converter'
      countriesList={[sendingCurrency, receivingCurrency]}
      primaryCurrency={sendingCurrency}
      secondaryCurrency={receivingCurrency}
      primaryCurrencyChangeHandler={onSwapHandler}
      secondaryCurrencyChangeHandler={onSwapHandler}
      sendingCurrencyValue={sendingRate}
      receivingCurrencyValue={receivingRate}
      onSwapHandler={onSwapHandler}
      sendAmount={sendingAmount}
      receiveAmount={receivingAmount}
      sendAmountLoading={sendAmountLoading || loading}
      receiveAmountLoading={receiveAmountLoading || loading}
      onSendCurrencyAmountChange={handleSendAmountChange}
      onReceiveCurrencyAmountChange={handleReceiveAmountChange}
      autoFocus={false}
      currencyRateLoading={currencyRateLoading}
      />
     : 
     <>
     {/* {type === 'horizontal' && isMobile && <Spacing variant='betweenHeaderToTab' />} */}
     <Box className={`${classes[`rb-exchange-box`]} ${classes['rb-exchange-horizontal']}`} >
        <CurrencyExchangeBox
        id='converter'
        countriesList={[sendingCurrency, receivingCurrency]}
        primaryCurrency={sendingCurrency}
        secondaryCurrency={receivingCurrency}
        primaryCurrencyChangeHandler={onSwapHandler}
        secondaryCurrencyChangeHandler={onSwapHandler}
        sendingCurrencyValue={sendingRate}
        receivingCurrencyValue={receivingRate}
        onSwapHandler={onSwapHandler}
        sendAmount={sendingAmount}
        receiveAmount={receivingAmount}
        sendAmountLoading={sendAmountLoading || loading}
        receiveAmountLoading={receiveAmountLoading || loading}
        onSendCurrencyAmountChange={handleSendAmountChange}
        onReceiveCurrencyAmountChange={handleReceiveAmountChange}
        autoFocus={false}
        currencyRateLoading={currencyRateLoading}
        />
        <Spacing variant='betweenSummaryCards' />
        <Box display='flex' flexDirection='column' justifyContent='space-between' className={classes['rb-info']}>
          <Box display='flex' gap={4} className={classes['rb-info-box']}>
              <Typography align='center'>Our exchange fee: </Typography>
              <Typography variant='body1' align='center' color={theme.palette.success.dark}>$0.00 CAD</Typography>
          </Box>
          <Box>
              <Button id='send-money' fullWidth variant='filledPrimary' onClick={()=>router.push('/exchange-currency')}>
                  Exchange now
              </Button>
          </Box>
        </Box>
    </Box></>}
    <RequestDialog request_type={'country'} open={openRequestDialog} handleClose={setOpenRequestDialog} isPublic />
    </>
  )
}

export default CurrencyExchangeRateDetails;